import React from 'react'
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";
import {del, get, post} from "../../utils/request";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Alert from "react-bootstrap/Alert";
import "react-datepicker/dist/react-datepicker.css";
import {humanize, unHumanize} from "../../utils/humanReadableSize";

var dayjs = require('dayjs');
const filesize = require('filesize');

const apiDateFormat = 'YYYY-MM-DD';

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class AccountClosure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loaded: false,
            accountData: {
                name: '',
                email: '',
                usage: '',
                status: '',
            },
            successMessage: '',
            errorMessage: '',
        };
        this.loadAccount = this.loadAccount.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.handleDeleteDataClick = this.handleDeleteDataClick.bind(this);
    }

    async loadAccount() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({accountData: res.data});
    }

    async componentDidMount() {
        await this.loadAccount();
        this.setState({loaded: true});
    }

    async handleCloseClick(event) {
        event.preventDefault();

        if (window.confirm('Are you sure you wish to close this account, this can not be undone?')) {
            this.setState({successMessage: ""});
            this.setState({errorMessage: ""});

            const path = "/account/" + this.state.id + "/close";
            const [_, err] = await post(this, path, null);

            if (err) {
                this.setState({errorMessage: "error: " + err});
                return;
            }
            this.setState({successMessage: "Account closed!"});
            await this.loadAccount();
        }
    }

    async handleDeleteDataClick(event) {
        event.preventDefault();

        if (window.confirm('Are you sure you wish to all data for this account, this can not be undone?')) {
            this.setState({successMessage: ""});
            this.setState({errorMessage: ""});

            const path = "/account/" + this.state.id + "/triggerDeleteAllData";
            const [res, err] = await post(this, path, null);

            if (err) {
                this.setState({errorMessage: "error: " + err});
                return;
            }

            this.setState({successMessage: "Deleting all data for account triggered for " + res.data.numBucketDeletesTriggered + " buckets!"});
            await this.loadAccount();
        }
    }

    render() {
        if (!this.state.loaded) {
            return <div>loading data...</div>
        }

        let status = <span className="badge badge-pill badge-warning">{this.state.accountData.status}</span>;
        if (this.state.accountData.status === "active") {
            status = <span className="badge badge-pill badge-success">{this.state.accountData.status}</span>;
        } else if (this.state.accountData.status === "closed") {
            status = <span className="badge badge-pill badge-danger">{this.state.accountData.status}</span>;
        }

        return <Layout activeMenu="account">
            <AccountNav activeKey="closure" id={this.state.id}/>
            <div style={divStyle}>
                {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                <h1>Account closure</h1>
                <br/>
                <p>
                    <b>Name: </b>{this.state.accountData.name}<br/>
                    <b>Email: </b>{this.state.accountData.email}<br/>
                    <b>Status: </b>{status}<br/>
                    <b>Created: </b>{this.state.accountData.created}<br/>
                    <b>Closed: </b>{this.state.accountData.closedTime}<br/>
                    <br/><b>Usage: </b>{filesize(this.state.accountData.usage)}
                </p>
                {this.state.accountData.status === "closed" &&
                    <Button variant="outline-primary" onClick={e => this.handleDeleteDataClick(e)}>Delete all data</Button>
                }
                <Button variant="outline-primary" onClick={e => this.handleCloseClick(e)}>Close account</Button>
            </div>
        </Layout>;
    }
}

export default AccountClosure