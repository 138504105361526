import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {get, put} from '../../utils/request'
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";
import CountrySelector from "../../component/CountrySelector";
import {CountryByCode} from "../../component/CountryByCode";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

const statuses = [
    {label: "", value: ""},
    {label: "Signup", value: "signup"},
    {label: "Waiting Confirmation", value: "waiting_confirm"},
    {label: "Waiting Activation", value: "waiting_activation"},
    {label: "Active", value: "active"},
    {label: "Closed", value: "closed"}
];

class AccountEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                name: '',
                email: '',
                emailForBilling: '',
                status: '',
                countryCode: '',
                registrationNumber: '',
                vatNumber: '',
                company: '',
                message: '',
                registrationCountryCode: '',
            },
            successMessage: '',
            errorMessage: '',
        };

        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleCountryCodeChange(newValue) {
        const data = this.state.data;
        data.countryCode = newValue;
        this.setState({data: data});
    }

    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.value;
        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const path = "/account/" + this.state.id;
        const data = this.state.data;

        const [res, err] = await put(this, path, data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({errorMessage: "This account email already exists"});
                        return;
                    case 412:
                        this.setState({errorMessage: "Can not set status to close, please use Account Closure to close account"});
                        return;
                    case 422:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }
        this.setState({successMessage: "Account updated!"});
        this.loadData();
    }

    render() {
        if (!this.state.data.id) {
            return <div>Loading data...</div>
        }

        return <Layout activeMenu="account">
            <AccountNav activeKey="edit" id={this.state.id} />
            <div style={divStyle}>
                <h1>Account edit</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    <b>Created: </b>{this.state.data.created}
                    {this.state.data.closedTime && <><br/><b>Closed: </b>{this.state.data.closedTime}</>}
                    <br/><a href={"/eventLog/?keyAccountId=" + this.state.id} target="_blank">Show related
                    events <>&#8599;</></a><br/><br/>
                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="3">Name</Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="name" defaultValue={this.state.data.name}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="3">Email</Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" name="email" defaultValue={this.state.data.email}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="3">Email for Billing</Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" name="emailForBilling" defaultValue={this.state.data.emailForBilling}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="3">Status</Form.Label>
                        <Col sm="9">
                            <Select
                                name="status"
                                options={statuses}
                                defaultValue={{
                                    value: this.state.data.status,
                                    label: this.capitalize(this.state.data.status)
                                }}
                                onChange={this.handleStatusChange}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCountryCode">
                        <Form.Label column sm="3">Country</Form.Label>
                        <Col sm="9">
                            <CountrySelector
                                selected={this.state.data.countryCode}
                                onChange={this.handleCountryCodeChange.bind(this)}
                            />
                        </Col>
                    </Form.Group>
                    {this.state.data.registrationCountryCode &&
                    this.state.data.registrationCountryCode !== this.state.data.countryCode &&
                    <Form.Group as={Row} controlId="formRegistrationCountryCode">
                        <Form.Label column sm="6">Country by registration ip:</Form.Label>
                        <Col sm="6">{CountryByCode(this.state.data.registrationCountryCode)}</Col>
                    </Form.Group>
                    }
                    <Form.Group as={Row} controlId="formAddress">
                        <Form.Label column sm="3" nowrap>Address</Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" as="textArea" name="address"
                                          onChange={this.handleChange}>{this.state.data.address}</Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompany">
                        <Form.Label column sm="3">Company</Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="company" defaultValue={this.state.data.company}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formRegistrationNumber">
                        <Form.Label column sm="5" nowrap>Registration number</Form.Label>
                        <Col sm="7">
                            <Form.Control type="text" name="registrationNumber" defaultValue={this.state.data.registrationNumber}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formVatNumber">
                        <Form.Label column sm="5" nowrap>VAT number</Form.Label>
                        <Col sm="7">
                            <Form.Control type="text" name="vatNumber" defaultValue={this.state.data.vatNumber}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formMessage">
                        <Form.Label column sm="3">Message</Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="message" as="textArea"
                                          onChange={this.handleChange}>{this.state.data.message}</Form.Control>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default AccountEdit